<template>
  <div>
    <v-container>
      <v-row>
        <BaseDateSelect
            :value="editedItem.date_signature"
            label="Date de signature"
            :refs="refs"
            @input="(data) => {update_ei({'field': 'date_signature', 'value': data})}"
        />
      </v-row>
      <v-row>
        <v-text-field type="number" :value="editedItem.duree_bail"
                      @input="update_ei({'field': 'duree_bail', 'value': $event})"
                      label="Durée (en années)"></v-text-field>
      </v-row>
      <v-row>
        <v-col cols="6" class="pl-0 py-0">
          <v-text-field type="number" :value="editedItem.loyer_bail"
                        @input="update_ei({'field': 'loyer_bail', 'value': $event})"
                        label="Loyer" cols="6"></v-text-field>
        </v-col>
        <v-col cols="6" class="pr-0 py-0">
          <BaseUnitSelect
              :value="editedItem.unite_loyer_bail"
              label="Unités"
              @input="(data) => {update_ei({'field': 'unite_loyer_bail', 'value': data})}"
          ></BaseUnitSelect>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {format_functions} from "@/mixins/UtilityMixin";
import {mapActions, mapState} from "vuex";

export default {
  name: "Bail",
  props: ['refs'],
  mixins: [format_functions],
  data() {
    return {
      defaultItem: {"date_signature": null, 'duree_bail': 41, 'loyer_bail': 5000, 'unite_loyer_bail': '€/Eol/mois'},
    }
  },
  created() {
    if (Object.keys(this.editedItem).length === 0) {
      this.init_ei(this.defaultItem)
    }
  },
  methods: {
    ...mapActions('edition', [
      'init_ei',
      'update_ei'
    ]),
  },
  computed: {
    ...mapState({
      editedItem: state => state.edition.editedItem
    }),
  },
}
</script>

<style scoped>

</style>